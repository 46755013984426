import map from 'lodash/fp/map';

/* global document, window */
const timeline = () => {
	const $ = window.jQuery;
	if (document.querySelector('.timeline-block')) {
		map((block) => {
			const wrapper = block.querySelector('.timeline-block-card-wrapper');
			if (wrapper) {
				// Slick.js was struggling when there weren't the full number of items,
				// so add dummy items to meet the minimum and then make sure we can't
				// navigate to them in the button click handler.
				const numberSlides = [...wrapper.querySelectorAll('.timeline-block-card')].length;
				if (numberSlides < 5) {
					/* eslint-disable no-plusplus */
					for (let i = numberSlides; i < 5; i++) {
						const el = document.createElement('div');
						el.classList.add('slick-filler');
						wrapper.appendChild(el);
					}
				}
				// Set the initial index from an ACF field that is added as a data
				// attribute. If the selected index is greater than the last slide, set
				// to the last slide.
				let initialSlide = parseInt(wrapper.getAttribute('data-start-on-slide'), 10);
				if (initialSlide > numberSlides - 1) {
					initialSlide = numberSlides - 1;
				}
				const $wrapper = $(wrapper);
				// Helper function for setting classes on the adjacent slides for
				// styling purposes.
				const setAdjacentClasses = (nextSlide) => {
					$('.slick-slide', $(wrapper)).removeClass('slick-one-after');
					$('.slick-slide', $(wrapper)).removeClass('slick-two-after');
					// Three after and three before added only because otherwise they grow
					// briefly when animating off screen.
					$('.slick-slide', $(wrapper)).removeClass('slick-three-after');
					$('.slick-slide', $(wrapper)).removeClass('slick-one-before');
					$('.slick-slide', $(wrapper)).removeClass('slick-two-before');
					$('.slick-slide', $(wrapper)).removeClass('slick-three-before');
					const $center = $('.slick-slide', $(wrapper)).eq(nextSlide);
					$center.prev().addClass('slick-one-before');
					$center.prev().prev().addClass('slick-two-before');
					$center.prev().prev().prev().addClass('slick-three-before');
					$center.next().addClass('slick-one-after');
					$center.next().next().addClass('slick-two-after');
					$center.next().next().next().addClass('slick-three-after');
				};
				// Manually handle arrow navigation since slick.js will automatically
				// hide the button when all of the slides are visible.
				const updateButtonStatus = () => {
					$('.slick-arrow', $wrapper.parent()).removeClass('slick-disabled').attr('aria-disabled', 'false');
					if (!$('.slick-center', $wrapper).prev().length) {
						$('.slick-prev', $wrapper.parent()).addClass('slick-disabled').attr('aria-disabled', 'true');
					}
					if (!$('.slick-center', $wrapper).next(':not(".slick-filler")').length) {
						$('.slick-next', $wrapper.parent()).addClass('slick-disabled').attr('aria-disabled', 'true');
					}
				};
				$wrapper.on('init', () => {
					setAdjacentClasses(initialSlide);
					updateButtonStatus();
					// Avoid animation blip by deferring visibility for an additional
					// 500ms.
					setTimeout(() => $(wrapper).addClass('slick-ready'), 500);
					/* eslint-disable consistent-return */
					$wrapper.parent().find('.slick-next').on('click touch', (evt) => {
						if ($(evt.target).hasClass('slick-disabled')) {
							evt.preventDefault();
							return false;
							//
						}
						$wrapper.slick('slickNext');
					});
					$wrapper.parent().find('.slick-prev').on('click touch', (evt) => {
						if ($(evt.target).hasClass('slick-disabled')) {
							evt.preventDefault();
							return false;
						}
						$wrapper.slick('slickPrev');
					});
				});
				const instance = $wrapper.slick({
					accessibility: true,
					arrows: false,
					initialSlide,
					slidesToShow: 3,
					slidesToScroll: 1,
					centerMode: true,
					infinite: false,
					responsive: [
						{
							breakpoint: 900,
							settings: {
								slidesToShow: 1,
							},
						},
					],
				});
				$('.timeline-block-card', $wrapper).off('click touch').on('click touch', function () {
					const $card = $(this);
					if ($card.hasClass('slick-center')) return false;
					const index = parseInt($card.attr('data-slick-index'), 10);
					$wrapper.slick('slickGoTo', index);
				});
				// Update behaviors before and after changing slides.
				instance.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
					setAdjacentClasses(nextSlide);
				});
				instance.on('afterChange', updateButtonStatus);
			}
		}, document.querySelectorAll('.timeline-block'));
	}
};

window.addEventListener('DOMContentLoaded', timeline);
